import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Swiper, Virtual } from 'swiper';
import { IonicSlides } from '@ionic/angular';
export const INTRO_KEY = 'intro-seen';

SwiperCore.use([Virtual]);

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.page.html',
  styleUrls: ['./introduction.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IntroductionPage implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  next() {
    this.swiper.swiperRef.slideNext(100);
  }
  prev() {
    this.swiper.swiperRef.slidePrev(100);
  }

  async finish() {
    await Storage.set({key: INTRO_KEY, value: 'true'});
    this.router.navigateByUrl('/tabs');
  }
}
