import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private toastCtrl: ToastController
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(resolve => {
      this.afAuth.user.subscribe(async user => {
        if (user) {
          resolve(true);
        } else {
          // console.log('User is not logged in');
          this.router.navigateByUrl('/', { replaceUrl: true });

          const toast = await this.toastCtrl.create({
            message: 'Sesión Finalizada con exito',
            position: 'bottom',
            duration: 2000
          });
          await toast.present();

          resolve(false);
        }
      });
    });
  }
}
